<template>
  <div>
    <div style="margin-top: 20px">
      <div class="example-preview table-responsive">
        <table class="table table-vertical-center table-bordered" element-loading-text="Loading...">
          <thead>
          <tr style="background-color: #F8F8F8;">
            <th>Quy trình</th>
            <th>Tác vụ lỗi</th>
            <th>Chi tiết lỗi</th>
            <th>Trạng thái</th>
            <th>Người phụ trách tác vụ</th>
            <th>Thời gian tạo</th>
            <th>Hành động</th>
          </tr>
          </thead>
          <tbody v-if="dataWarning?.length > 0">
          <tr v-for="item in dataWarning">
            <td>{{ item.processingProcedure }}</td>
            <td>{{ item.failedTask }}</td>
            <td>{{ item.detailError }}</td>
            <td>
              <span :class="item.statusColor" style="padding: 5px 10px; border-radius: 4px; white-space: nowrap">{{ item.status }}</span>
            </td>
            <td>{{ item.personInCharge }}</td>
            <td>{{ item.createdAt }}</td>
            <td>
              <div class="d-flex justify-content-center">
                <el-button
                    title="Xoá" @click="openDialogDelete(item.id, item.title)"
                    v-if="[1,2].includes(item.typeError)" style="border-color: red" class="p-1 btn-delete"><i
                    style="color: red;" class="el-icon-delete"></i>
                </el-button>
                <el-button
                    title="Sửa" @click="openDialogEdit(item.id, item.title)"
                    v-if="item?.type === 4 && item.typeError === 1" style="border-color: green" class="p-1"><i
                    style="color: green;" class="bi bi-shield-check"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="edu-paginate mx-auto text-center" style="display: flex; align-items: center; justify-content: center;">
        <div class="position-absolute" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
            total ?? 0
          }}</b></div>
        <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1"
                  :click-handler="clickCallback"
                  :prev-text="'Trước'" :next-text="'Sau'"
                  :container-class="'pagination b-pagination'"
                  :pageLinkClass="'page-link'"
                  :next-link-class="'next-link-item'"
                  :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                  :next-class="'page-link'" :page-class="'page-item'">
        </paginate>
      </div>
    </div>

    <!-- dialog Sửa lỗi -->
    <el-dialog top="30vh" width="30%" title="Cập nhật trạng thái thành đã sửa" :visible.sync="dialogEditError">
      <div style="word-break:break-word;">
        <span>Bạn có chắc chắn muốn cập nhật trạng thái lỗi “{{ dataEdit.title }}” thành <b>đã sửa lỗi</b> không?</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="rounded" size="mini" style="background-color: #CACACA; color: #000;"
                   @click="dialogEditError = false">Hủy bỏ
        </el-button>
        <el-button class="rounded" size="mini" style="background-color: #5D04B0; color: #F8F8F8;" @click="editError">Xác
          nhận
        </el-button>
      </div>
    </el-dialog>
    <el-dialog top="30vh" width="30%" title="Xoá lỗi" :visible.sync="dialogDeleteError">
      <div style="word-break:break-word;">
        <span>Bạn có chắc chắn muốn xóa lỗi “{{ dataDelete.title }}” không?</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="rounded" size="mini" style="background-color: #CACACA; color: #000;"
                   @click="dialogDeleteError = false">Hủy bỏ
        </el-button>
        <el-button class="rounded" size="mini" style="background-color: #5D04B0; color: #F8F8F8;" @click="deleteError">
          Xác nhận
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {DELETE_ERROR, EDIT_ERROR} from "../../../../core/services/store/report-error/reportError.module";
import { STATUS_ERROR_CSS } from "../../../../core/option/warningError";

export default {
  name: "Table",
  components: {},
  props: {
    dataWarning: {
      type: Array,
      default: []
    },
    dataDeFileError: {
      type: Object,
      default: () => {
        return {};
      }
    },
    last_page: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      total: '',
      dialogDeleteError: false,
      dialogEditError: false,
      statusDefineCss: STATUS_ERROR_CSS,
      dataEdit: {
        id: '',
        title: ''
      },
      dataDelete: {
        id: '',
        title: ''
      },
    }
  },
  methods: {
    openDialogEdit(id, title) {
      this.dataEdit.id = id;
      this.dataEdit.title = title;
      this.dialogEditError = true;
    },
    openDialogDelete(id, title) {
      this.dataDelete.id = id;
      this.dataDelete.title = title;
      this.dialogDeleteError = true;
    },
    editError() {
      this.$store.dispatch(EDIT_ERROR, {
        id: this.dataEdit.id
      }).then((res) => {
        if (!res.error) {
          this.$notify({
            title: 'Thành công!',
            message: 'Sửa lỗi thành công!',
            type: 'success'
          });
        }
        this.dialogEditError = false;
        this.$emit('emit', 1);
      });
    },
    deleteError() {
      this.$store.dispatch(DELETE_ERROR, {
        id: this.dataDelete.id
      }).then((res) => {
        if (!res.error) {
          this.$notify({
            title: 'Thành công!',
            message: 'Xoá lỗi thành công!',
            type: 'success'
          });
        }
        this.dialogDeleteError = false;
        this.$emit('emit', 1);
      });
    },
    clickCallback(obj) {
      this.$emit('emit', obj);
    },
  }
}
</script>

<style scoped>
.bg-success {
  background: #1BC5BD !important;
  color: white !important;
}
.bg-warning {
  background: #FF9900 !important;
}
.bg-gray {
  background: #6A6A6A !important;
  color: white !important;
}
.b-pagination {
  margin: 0 !important;
}
</style>