<template>
  <div>
    <span style="font-weight: 600; font-size: 14px; margin-bottom: 5px; display: block; text-transform: uppercase">{{responseError.title}}</span>
    <div style="display: flex; gap: 20px">
      <div class="card-error" :class="responseError.totalErrorTest > 0 ? 'bg-card-red' : 'bg-card-green'">
        <span style="font-size: 25px">{{ responseError.totalErrorTest }}</span>
        <span style="color: #425166; margin-top: 5px; font-size: 18px">Test đầu vào</span>
        <span>Số lỗi ngày hôm nay: <span style="color: #4079ED">{{ responseError.errorTestToday }}</span></span>
        <span>Lỗi gần nhất: <span style="color: #4079ED">{{ responseError.errorTestNewest }}</span></span>
      </div>
      <div class="card-error" :class="responseError.totalErrorTeaching > 0 ? 'bg-card-red' : 'bg-card-green'">
        <span style="font-size: 25px">{{ responseError.totalErrorTeaching }}</span>
        <span style="color: #425166; margin-top: 5px; font-size: 18px">Dạy học</span>
        <span>Số lỗi ngày hôm nay: <span style="color: #4079ED">{{ responseError.errorTeachingToday }}</span></span>
        <span>Lỗi gần nhất: <span style="color: #4079ED">{{ responseError.errorTeachingNewest }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardError',
  props: {
    responseError: {
      type: Object,
      default: {
        title: String,
        data: []
      },
      required: true,
    }
  }
}
</script>
<style scoped>
.card-error {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 213px;
  padding: 15px;
}
.bg-card-red {
  background: #FFE2E5;
}
.bg-card-green {
  background: #E2FFEC;
}
</style>