<template>
  <div class="" id="classError" v-loading="loading">
    <div>
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; margin-top: 20px">
        <div style="padding: 20px; border-radius: 10px; background: white" v-for="item in totalError">
          <CardError :response-error="item" />
        </div>
      </div>
      <div class="card card-body" style="margin-top: 20px; border-radius: 10px">
        <div class="d-flex justify-content-between">
          <div class="card-title">
            <h3 style="margin-bottom: 0">Danh sách lỗi</h3>
          </div>
          <div class="card-title">
            <button class="btn btn-success font-weight-bold mr-2"
              :class="{ 'spinner spinner-white spinner-left': is_export_excel }" :disabled="is_export_excel"
              @click="exportExcel">
              <i class="el-icon-download" v-if="!is_export_excel"></i> Export
            </button>
            <button class="btn btn-primary font-weight-bold" @click="showModalAddError">
              <i class="el-icon-plus"></i>
              Thêm mới
            </button>
          </div>
        </div>
        <div class="grid-filter">
          <div>
            <span class="title-filter">Nội dung lỗi</span>
            <el-input placeholder="Nhập nội dung lỗi" v-model="query.title"></el-input>
          </div>
          <div>
            <span class="title-filter">Quy trình phát sinh lỗi</span>
            <el-select @change="getListErrorByPhase()" v-model="query.process" filterable class="w-100"
              placeholder="Chọn quy trình" clearable>
              <el-option v-for="item in phaseList" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="title-filter">Tác vụ phát sinh lỗi</span>
            <el-select v-model="query.process_type" filterable class="w-100" placeholder="Chọn tác vụ" clearable>
              <el-option v-for="item in listError" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="title-filter">Trạng thái</span>
            <el-select v-model="query.type_error" filterable class="w-100" placeholder="Chọn trạng thái" clearable>
              <el-option v-for="item in statusReportError" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="title-filter">Trung tâm</span>
            <el-select @change="handleCenter()" v-model="query.center_id" filterable class="w-100"
              placeholder="Chọn trung tâm" clearable>
              <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="title-filter">Chi nhánh</span>
            <el-select v-model="query.branch_id" filterable class="w-100" placeholder="Chọn chi nhánh" clearable>
              <el-option v-for="item in branches" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="title-filter">Thời gian tạo</span>
            <date-picker placeholder="Chọn khoảng thời gian" format="DD-MM-YYYY" range valueType="YYYY-MM-DD"
              v-model="query.date_range">
            </date-picker>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <button class="btn btn-primary font-weight-bold" style="height: 38px; margin-bottom: 2px"
              @click="getTotalError(); searchListError()"
              :class="{ 'spinner spinner-white spinner-left': is_disable_search }" :disabled="is_disable_search">
              <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
            </button>
          </div>
        </div>
        <TableWarningError :dataWarning="listWarning" :dataDeFileError="dataDeFileError" :last_page="last_page"
          :page="page" :total="totalData" @emit="emitTable">
        </TableWarningError>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible" width="60%">
      <el-row :gutter="10" class="pr-2 pl-2">
        <detail-error v-if="dialogFormVisible" @completed="completedViewDetail" :process_type="query.process_type"
          :process="process" :totalError="totalError" :center_id="query.center_id" :branch_id="query.branch_id"
          @click-detail="getTotalError" :date_range="query.date_range">
        </detail-error>
      </el-row>
    </el-dialog>
    <FormErrorTeaching @reloadTableError="getListError">
    </FormErrorTeaching>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import {
  GET_TOTAL_ERROR,
  EXPORT_ERROR_LIST,
  EXPORT_ERROR_LIST_V2,
  GET_LIST_ERROR
} from "../../../core/services/store/report-error/reportError.module";
import { PROCESS_TEST_INCOME, PROCESS_TEACHING, } from "../../../core/option/warningError";
import DetailError from "./DetailError";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import { GET_BRANCHES } from "@/core/services/store/center/branch.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { exportExcel } from "../../../core/filters";
import FormErrorTeaching from "./component/FormErrorTeaching.vue"
import { GET_ALL_CLASS } from "../../../core/services/store/course/classes.module";
import {
  TYPE_ERROR_TEST_ICOME,
  TYPE_ERROR_TEACHING,
  LIST_ERROR,
  TYPE_ERROR_DEFINE
} from "../../../core/option/warningError";
import CardError from "./component/CardError.vue";
import { ErrorIndexModel, ReportErrorModel } from "./model/ReportErrorModel";
import { statusReportError } from "../../../common/define";
import TableWarningError from "./component/TableWarningError.vue";

const _ = require('lodash');

export default {
  components: {
    CardError,
    DetailError,
    DatePicker,
    FormErrorTeaching,
    TableWarningError
  },
  name: "Error-List",
  data() {
    return {
      page: 1,
      last_page: 1,
      totalData: 0,
      totalError: [],
      statusReportError,
      PROCESS_TEST_INCOME,
      PROCESS_TEACHING,
      dialog: {
        title: ''
      },
      dialogFormVisible: false,
      process: '',
      process_type: '',
      centers: [],
      branches: [],
      listError: '',
      dataDeFileError: TYPE_ERROR_DEFINE,
      listClass: {},
      dataDefile: {},
      query: {
        center_id: null,
        branch_id: null,
        date_range: null,
        process: null,
        process_type: null,
        type_error: null,
        title: null,
      },
      phaseList: [
        { id: 1, name: 'Test đầu vào' },
        { id: 2, name: 'Dạy học' },
      ],
      is_disable_search: false,
      is_export_excel: false,
      loading: true,
      listWarning: [],
    }
  },
  mounted() {
    this.getListErrorByPhase();
    this.getCenters();
    this.getListError();
    this.getTotalError();
    this.getAllClass();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý lỗi", route: 'report-error-index' },
      { title: "Bảng báo lỗi" }
    ]);
  },
  methods: {
    showModalAddError() {
      this.$bvModal.show(`add-error-teaching`);
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1,
        is_warning_error: 1
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        status: 1,
        center_id: this.query.center_id,
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    handleCenter() {
      this.query.branch_id = []
      if (this.query.center_id) {
        this.getBranches()
      } else {
        this.branches = []
      }
    },
    completedViewDetail() {
      this.dialogFormVisible = false
    },
    searchListError() {
      this.page = 1;
      this.getListError()
    },
    getListError() {
      this.loading_table = true;
      const payload = {
        page: this.page,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        date_range: this.query.date_range,
        process: this.query.process,
        process_type: this.query.process_type,
        type_error: this.query.type_error,
        title: this.query.title
      }
      this.$store.dispatch(GET_LIST_ERROR, payload).then(res => {
        if (!res.error) {
          this.listWarning = res.data.map(item => {
            return new ReportErrorModel(item)
          });
          this.is_disable_search = false
          this.totalData = res.pagination.total;
          this.last_page = res.pagination.last_page;
          this.loading = false;
        }
      }).catch(error => {
        this.loading = false;
        console.log(error, 'get list error field!')
      });
    },
    getTotalError() {
      this.is_disable_search = true
      this.$store.dispatch(GET_TOTAL_ERROR, {
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        date_range: this.query.date_range,
        process: this.query.process,
        process_type: this.query.process_type,
        type_error: this.query.type_error,
        title: this.query.title
      }).then(res => {
        if (!res.error) {
          const totalRecord = {
            center_id: "null",
            total_error_test_income: res.data.reduce((sum, item) => sum + Number(item.total_error_test_income || 0), 0),
            total_error_teaching: res.data.reduce((sum, item) => sum + Number(item.total_error_teaching || 0), 0),
            total_errors_today_test_income: res.data.reduce((sum, item) => sum + Number(item.total_errors_today_test_income || 0), 0),
            total_errors_today_teaching: res.data.reduce((sum, item) => sum + Number(item.total_errors_today_teaching || 0), 0),
            time_new_test_income: this.getClosestTime(res.data, "time_new_test_income"),
            time_new_test_teaching: this.getClosestTime(res.data, "time_new_test_teaching"),
            center: {
              id: null,
              name: "TẤT CẢ TRUNG TÂM"
            }
          };

          const newData = [totalRecord, ...res.data];
          this.totalError = newData?.map(item => {
            return new ErrorIndexModel(item)
          })
          this.is_disable_search = false
        }
      }).catch(res => {
        console.log(res);
      });
    },
    getClosestTime(data, field) {
      const now = new Date().getTime();
      return data
        .map(item => item[field])
        .filter(time => time)
        .map(time => ({
          time,
          diff: Math.abs(new Date(time).getTime() - now)
        }))
        .sort((a, b) => a.diff - b.diff)[0]?.time || "";
    },
    getListErrorByPhase() {
      this.query.process_type = '';
      switch (this.query.process) {
        case 1:
          this.listError = TYPE_ERROR_TEST_ICOME;
          break;
        case 2:
          this.listError = TYPE_ERROR_TEACHING;
          break;
        default:
          this.listError = LIST_ERROR;
          break;
      }
    },
    getAllClass() {
      this.$store.dispatch(GET_ALL_CLASS, {}).then(res => {
        if (!res.error) {
          this.listClass = res.data;
        }
      }).catch(res => {
        console.log(res);
      });
    },
    emitTable(obj) {
      this.page = obj;
      this.getListError();
    },
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_ERROR_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'process',
          value: 'Khâu',
        },
        {
          key: 'task',
          value: 'Task việc',
        },
        {
          key: 'center_name',
          value: 'Trung tâm',
        },
        {
          key: 'branch_name',
          value: 'Chi nhánh',
        },
        {
          key: 'type_error',
          value: 'Trạng thái',
        },
        {
          key: 'message',
          value: 'Nội dung',
        },
        {
          key: 'date_appear',
          value: 'Thời gian báo lỗi',
        },
        {
          key: 'date_fixed',
          value: 'Thời gian sửa lỗi',
        },
        {
          key: 'user_error_id',
          value: 'Mã nhân sự phụ trách',
        },
        {
          key: 'user_error_name',
          value: 'Người phụ trách',
        },
        {
          key: 'classroom_name',
          value: 'Lớp học',
        },
        {
          key: 'lesson',
          value: 'Buổi học',
        },
      ]
      this.$store.dispatch(EXPORT_ERROR_LIST, {
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        date_range: this.query.date_range,
        process: this.query.process,
        process_type: this.query.process_type,
        class_id: this.query.class_id,
        title: this.query.title,
        is_bang_bao_loi_spnn: 1,
        is_export: 1,
      }).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_ERROR_LIST, res.data, "Danh sách lỗi.xlsx", true)
        }
      });
    },
    exportExcelV2() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_ERROR_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'process',
          value: 'Khâu',
        },
        {
          key: 'task',
          value: 'Task việc',
        },
        {
          key: 'center_name',
          value: 'Trung tâm',
        },
        {
          key: 'branch_name',
          value: 'Chi nhánh',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'message',
          value: 'Nội dung',
        },
        {
          key: 'date_appear',
          value: 'Thời gian báo lỗi',
        },
        {
          key: 'updated_at',
          value: 'Thời gian sửa lỗi',
        },
      ]
      this.$store.dispatch(EXPORT_ERROR_LIST_V2, {
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        date_range: this.query.date_range,
        is_bang_bao_loi_spnn: 1,
        is_export: 1,
      }).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          exportExcel(HEADER_ROW_EXPORT_ERROR_LIST, res.data, "Danh sách lỗi.xlsx", true)
        }
      });
    },
  }
}
</script>
<style>
.grid-filter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 30px
}

.process {
  border: 1px solid #9f9dad;
  border-radius: 8px;
}

.process-error {
  border: 1px solid #d52323 !important;
}

.process-error-header {
  background-color: #d52323 !important;
}

.process-no-error {
  border: 1px solid #14ad14 !important;
}

.process-no-error-header {
  background-color: #14ad14 !important;
}

.total-error {
  border: 1px solid;
  border-radius: 5px;
  margin-top: 20px;
  padding: 2px 10px 2px 10px;
}

.el-dialog__header {
  border: 1px solid #EBEDF3 !important;
  text-align: center;
}

.process-select {
  cursor: pointer;
}

.title-filter {
  display: block;
  margin-bottom: 6px;
}

@media only screen and (min-width: 768px) {
  .grid-filter {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1028px) {
  .grid-filter {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1280px) {
  .grid-filter {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
