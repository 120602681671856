import moment from "moment-timezone";
import {DIFFERENCE_NUMBER_USER} from "../../../../core/option/userOption";

export class ReportErrorModel {
    constructor(record) {
        this.id = record.id;
        this.processingProcedure = record.phase ? this.getProcessingProcedure(record.phase) : '';
        this.failedTask = record.type_error ? this.getListFieldTask(record.type) : '';
        this.detailError = record.title ?? '';
        this.status = record.type_error ? this.getStatus(record.type_error) : '';
        this.typeError = record.type_error ?? '';
        this.type = record.type;
        this.personInCharge = record.user ? `${record.user.id + DIFFERENCE_NUMBER_USER}-${record.user.name}` : '';
        this.createdAt = record.date_appear ? moment(record.date_appear).format(' HH:mm:ss DD-MM-YYYY') : '';
        this.statusColor = record.type_error ? this.getColorStatus(record.type_error) : null;
        this.title = record.title ?? ''
    }
    getStatus(status) {
        switch (status) {
            case 1:return 'Chưa xử lý'
            case 2: return 'Đã xử lý'
            case 3: return 'Huỷ'
            default: return ''
        }
    }
    getProcessingProcedure(processingProcedure) {
        if (processingProcedure === 1) {
            return 'Test đầu vào'
        }
        return 'Dạy học'
    }
    getListFieldTask(task) {
        switch (task) {
            case 0:
                return 'Thanh tra'
            case 1:
                return 'Điểm danh'
            case 2:
                return 'Nhận xét'
            case 3:
                return 'Chấm BTVN'
            case 4:
                return 'Mở slide'
            case 5:
                return 'Tặng quà'
            case 6:
                return 'Vào test Online'
            case 7:
                return 'Chấm điểm speaking'
            case 9:
                return 'Chấm điểm general'
            case 10:
                return 'Tư liệu'
            case 12:
                return 'Duyệt team'
        }
    }
    getColorStatus(status) {
        switch (status) {
            case 1:
                return 'bg-warning'
            case 2:
                return 'bg-success'
            case 3:
                return 'bg-gray'
            default:
                return ''
        }
    }
}

export class ErrorIndexModel {
    constructor(index) {
        this.centerId = index.center_id
        this.title = index.center.name;
        this.errorTestToday = Number(index.total_errors_today_test_income) ?? 0
        this.errorTeachingToday = Number(index.total_errors_today_teaching) ?? 0
        this.totalErrorTeaching = Number(index.total_error_teaching) ?? 0
        this.totalErrorTest = Number(index.total_error_test_income) ?? 0
        this.errorTestNewest = index.time_new_test_income ? this.formatTimeFromNow(index.time_new_test_income) : ''
        this.errorTeachingNewest = index.time_new_test_teaching ? this.formatTimeFromNow(index.time_new_test_teaching) : ''
    }
    formatTimeFromNow(timeString, timezone = "Asia/Ho_Chi_Minh") {
        const now = moment().tz(timezone);
        const time = moment(timeString).tz(timezone);
        const diffInMinutes = now.diff(time, "minutes");
        const diffInHours = now.diff(time, "hours");
        const diffInDays = now.diff(time, "days");

        if (diffInMinutes < 1) return "một phút trước";
        if (diffInMinutes < 60) return `${diffInMinutes} phút trước`;
        if (diffInHours === 1) return "1 giờ trước";
        if (diffInHours < 24) return `${diffInHours} giờ trước`;
        if (diffInDays === 1) {
            return time.format("HH:mm") !== "00:00" ? `hôm qua lúc ${time.format("HH:mm")}` : "hôm qua";
        }
        if (diffInDays < 7) return `${diffInDays} ngày trước`;
        if (diffInDays === 7) return "1 tuần trước";

        return time.format("DD-MM-YYYY");
    }
}